<div fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="8px" class="vip-rewards-container">
	<mat-card fxFlex>
		<mat-card-title>Your Point Balance</mat-card-title>
		<mat-card-content>
			<p style="text-align: center">
				<span class="mat-headline">{{vipRewardsPoints}}</span> points
			</p>
		</mat-card-content>
	</mat-card>

	<mat-card flex>
		<mat-card-title>Service and Car Wash Rewards</mat-card-title>
		<mat-card-content fxLayout="row">
			<mat-list fxFlex="45px" class="points-list">
				<mat-list-item>
					<b>Points</b>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					300
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					400
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					1000
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					1500
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					2500
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					2500
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					3000
				</mat-list-item>

			</mat-list>

			<mat-divider class="point-reward-divider" [vertical]="true"></mat-divider>

			<mat-list>
				<mat-list-item>
					<b>Reward</b>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Time Saver Car Wash <span class="disclaimer">&nbsp;*</span>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Ultimate Car Wash <span class="disclaimer">&nbsp;*</span>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					$20 Service Certificate
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Tire Rotation
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Time Saver Wash Card (10 ct) <span class="disclaimer">&nbsp;*</span>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					$50 Service Certificate
				</mat-list-item>

				<mat-divider></mat-divider>

				<mat-list-item>
					Ultimate Wash Card (10 ct) <span class="disclaimer">&nbsp;*</span>
				</mat-list-item>
			</mat-list>
		</mat-card-content>

		<div class="mat-caption disclaimer">
			* Car Wash Rewards available at locations with on-site car wash
		</div>
	</mat-card>

	<mat-card flex>
		<mat-card-title>Express Lube Oil Change Rewards</mat-card-title>
		<mat-card-content>
			<mat-list fxFlex="45px" class="points-list">
				<mat-list-item>
					<b>Points</b>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					2120
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					2600
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					3080
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					3560
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					4040
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					4520
				</mat-list-item>
			</mat-list>

			<mat-divider class="point-reward-divider" [vertical]="true"></mat-divider>

			<mat-list>
				<mat-list-item>
					<b>Reward</b>
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Up to $46
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Up to $61
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Up to $76
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Up to $81
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Up to $106
				</mat-list-item>
				<mat-divider></mat-divider>

				<mat-list-item>
					Up to $121
				</mat-list-item>
			</mat-list>
		</mat-card-content>
	</mat-card>
</div>
