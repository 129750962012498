<div class="my-vehicles-container">
	<div
			fxLayout="row wrap"
			fxLayout.lt-sm="column"
			fxLayoutGap="32px"
			fxLayoutAlign="space-evenly"> <!-- TODO Check to see if fxLayoutGap is causing the issues -->
		<ma-vehicle-card
				fxFlex="0 1 calc(33.3% - 32px)"
				fxFlex.lt-md="0 1 calc(50% - 32px)"
				fxFlex.lt-sm="100%"
				*ngFor="let vehicle of vehicles"
				[year]="vehicle.year"
				[make]="vehicle.make"
				[model]="vehicle.model"
				[license]="vehicle.license"
				[recommendedServices]="vehicle.recommendedServices"
				[recommendedServiceSeverity]="vehicle.aggregateSeverity"
				[vehicleId]="vehicle.vehicleID"
				[nextOilChangeOdometer]="vehicle.nextOilChangeOdometer"
				[nextOilChangeDate]="vehicle.nextOilChangeDate"
				[estimatedOilLifeConsumedPercentage]="vehicle.estimatedOilLifeConsumedPercentage"
				(vehicleDeleted)="onVehicleDeleted($event)">
		</ma-vehicle-card>
	</div>
</div>
