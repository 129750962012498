<div class="my-coupons-container">
	<ng-container *ngIf="hasLoadedCoupons">
		<div *ngIf="coupons.length > 0"
			fxLayout="row wrap"
			fxLayout.lt-sm="column"
			fxLayoutAlign="space-between center">
			<ma-coupon-card
				fxFlex.lg="25%"
				fxFlex.lt-lg="33%"
				fxFlex.lt-md="50%"
				fxFlex.lt-sm="100%"
				*ngFor="let coupon of coupons"
				[imgSrc]="coupon.imgSrc"
				[offerExclusive]="coupon.offerExclusive">
			</ma-coupon-card>
		</div>
		<ng-container *ngIf="coupons.length === 0">
			<div class="mat-h1">
				No offers available at this time.
			</div>
			<div class="mat-h3">
				Check back later for new offers.
			</div>
		</ng-container>
	</ng-container>
</div>
