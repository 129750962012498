<mat-toolbar fxLayout.lt-sm="column" fxLayout.gt-xs="row">
	<mat-toolbar-row>
		<mat-form-field class="vehicle-selector" floatLabel="always">
			<mat-label>Vehicles in history</mat-label>
			<mat-select
					placeholder="All of my vehicles"
					[(value)]="vehicleId"
					(selectionChange)="onSelectionChange($event)">
				<mat-option>All of my vehicles</mat-option>
				<mat-option *ngFor="let vehicle of myVehicles" [value]="vehicle.vehicleID">
					{{vehicle.year}} {{vehicle.make}} {{vehicle.model}} ({{vehicle.license}})
				</mat-option>
			</mat-select>
		</mat-form-field>
	</mat-toolbar-row>
	<mat-toolbar-row>
		<mat-form-field floatLabel="always">
			<mat-label>Search</mat-label>
			<mat-icon matPrefix>search</mat-icon>
			<input matInput type="text" [(ngModel)]="searchQuery">
			<button mat-button *ngIf="searchQuery" matSuffix mat-icon-button aria-label="Clear" (click)="searchQuery=''">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</mat-toolbar-row>
	<mat-toolbar-row class="button-row">
		<button mat-button color="accent" aria-label="Expand all" (click)="accordion.openAll()">Expand all</button>
		<button mat-button color="accent" aria-label="Collapse all" (click)="accordion.closeAll()">Collapse all</button>
	</mat-toolbar-row>
</mat-toolbar>
<div class="vehicle-history-container">
	<mat-accordion multi="true">
		<mat-expansion-panel *ngFor="let entry of vehicleHistory | filter:searchQuery">

			<mat-expansion-panel-header>
				<mat-panel-title>
					<span class="mat-body-2">{{entry.Year}} {{entry.Make}} {{entry.Model}}</span>
				</mat-panel-title>
				<mat-panel-description fxLayoutAlign="space-between center">
					<!-- Spacer when xs or sm -->
					<div flex *ngIf="!media.isActive('gt-sm')"></div>

					<!-- Work Description when gt-sm -->
					<div fxFlex="nogrow" *ngIf="media.isActive('gt-sm')">
						<!-- Display only the first 3 work description entries to prevent overflow -->
						<!-- CSS flex has issues preventing overflow of text elements, even when width constrained. -->
						<!-- https://www.westerndevs.com/css/Using-Overflow-Ellipsis-in-Inline-Flex/ -->
						<ng-container *ngFor="let line of entry.Description.concat(entry.PartsDescription); let last=last; let i = index; let count = count">
							<span>
								<ng-container *ngIf="i < 3;">
									{{line}}<ng-container *ngIf="!last || i < 2">,</ng-container>
								</ng-container>
							</span>
							<span *ngIf="count == 3">&nbsp;...</span>
							<!-- if count greater than 3, do nothing -->
						</ng-container>

					</div>

					<!-- Always show completion date floated right -->
					<span class="mat-caption completion-date">{{entry.CompletionDate | date: 'shortDate'}}</span>
				</mat-panel-description>
			</mat-expansion-panel-header>

			<div fxLayout="column" fxLayoutAlign="space-between center" fxFill>
				<div class="description-row">
					<span fxFlex="110px" class="mat-body-2">Order:</span>
					<span>{{entry.OrderId.substring(3)}}</span>
				</div>
				<div class="description-row" *ngIf="entry.Amount > 0">
					<span fxFlex="110px" class="mat-body-2">Amount:</span>
					<span>{{entry.Amount | currency:'USD'}}</span>
				</div>
				<div class="description-row" *ngIf="entry.BalanceDue > 0">
					<span fxFlex="110px" class="mat-body-2">Balance due:</span>
					<span>{{entry.BalanceDue | currency:'USD'}}</span>
				</div>
				<div class="description-row"  *ngIf="!!entry.Odometer">
					<span fxFlex="110px" class="mat-body-2">Odometer:</span>
					<span>{{entry.Odometer}}</span>
				</div>
				<div class="description-row" *ngIf="entry.Description.length > 0">
					<span class="mat-body-2">Work performed:</span>
					<ul>
						<li *ngFor="let line of entry.Description">{{line}}</li>
					</ul>
				</div>
				<div class="description-row" *ngIf="entry.PartsDescription.length > 0">
					<span class="mat-body-2">Parts description:</span>
					<ul>
						<li *ngFor="let line of entry.PartsDescription">{{line}}</li>
					</ul>
				</div>
			</div>

			<mat-action-row>
				<a
					mat-button
					color="accent"
					target="_blank"
					[href]="entry.InvoiceLink | safeUrl">
					View invoice
				</a>
			</mat-action-row>

		</mat-expansion-panel>
	</mat-accordion>

	<button [disabled]="!canLoadMore"
	        class="load-more"
	        mat-raised-button
	        color="accent"
	        aria-label="Load more"
	        (click)="loadMore()">
		Load more
	</button>
</div>

