<mat-card>
	<mat-card-header fxFlexFill>
<!--		<ma-recommended-service-severity -->
<!--				mat-card-avatar-->
<!--				[severity]="recommendedServiceSeverity">-->
<!--		</ma-recommended-service-severity>-->
		<mat-card-title>
			{{year}} {{make}} {{model}}
		</mat-card-title>
		<mat-card-subtitle>
			{{license}}
		</mat-card-subtitle>

<!--		<div fxFlex></div>-->

<!--		<button mat-icon-button [matMenuTriggerFor]="menu">-->
<!--			<mat-icon class="menu-button">more_vert</mat-icon>-->
<!--		</button>-->
<!--		<mat-menu #menu="matMenu">-->
<!--			<button mat-menu-item>-->
<!--				<mat-icon>loyalty</mat-icon>-->
<!--				<span>Coupons</span>-->
<!--			</button>-->
<!--			<button mat-menu-item>-->
<!--				<mat-icon>history</mat-icon>-->
<!--				<span>History</span>-->
<!--			</button>-->
<!--		</mat-menu>-->
	</mat-card-header>

	<mat-card-content>
		<div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
			<div class="oil-change-reminder" *ngIf="nextOilChangeDate && nextOilChangeOdometer"
				[ngClass]="{
					'due-soon': estimatedOilLifeConsumedPercentage >= 95 && estimatedOilLifeConsumedPercentage < 140,
					'estimated-overdue': estimatedOilLifeConsumedPercentage >= 140
				}">
				<p>Next oil change due</p>
				<ul>
					<li>Date: {{nextOilChangeDate | date:'shortDate'}}, or</li>
					<li>Mileage: {{nextOilChangeOdometer | number: '': 'en-US' }}</li>
				</ul>
			</div>
		</div>
	</mat-card-content>

	<mat-card-actions>
		<!-- 				ngx-analyticsOn="click"
				angularticsAction="Opened Services Needed"
				angularticsLabel="Vehicle Card - Dialog"
				angularticsCategory="Recommended Services"
				-->
		<button
				[disabled]="recommendedServices.length === 0"
				mat-button
				(click)="openRecommendedServicesDialog()"
				color="accent"
		>
			<ma-recommended-service-severity [showHelpText]="false" [severity]="recommendedServiceSeverity"></ma-recommended-service-severity>

			<ng-container *ngIf="recommendedServices.length === 0">
				No service needed
			</ng-container>
			<ng-container *ngIf="recommendedServices.length > 0">
				Service needed
			</ng-container>
		</button>
		<button [routerLink]="['/schedule/', vehicleId]" mat-button color="accent">Schedule</button>

		<div fxFlex></div>

		<!-- 				ngx-analyticsOn="click"
				angularticsAction="Opened Menu"
				angularticsLabel="More Items"
				angularticsCategory="Vehicle"
				-->
		<button
				mat-icon-button
				[matMenuTriggerFor]="menu">
			<mat-icon class="menu-button">more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<button [routerLink]="['/coupons/', vehicleId]" mat-menu-item>
				<mat-icon>loyalty</mat-icon>
				<span>Coupons</span>
			</button>
			<!--
				The vehicle history option is disabled when a VIN is not available, as it does not
				make sense to navigate to the vehicle history page for a given vehicle when a VIN is not provided,
				as it will show history for all vehicles.

				This is different from the coupons feature as some coupons shown will be relevant to the client
				regardless of whether or not they are targeted for a given vehicle.

				The reason sometimes vehicles are returned without a VIN is when an appointment is scheduled via the app,
				the back end considers them a "vehicle" immediately instead of having a separate staging collection
				used for holding these partial vehicle objects.
			-->
			<button [disabled]="!vehicleId" [routerLink]="['/history/', vehicleId]" mat-menu-item>
				<mat-icon>history</mat-icon>
				<span>History</span>
			</button>
			<button [disabled]="!vehicleId" (click)="openVehicleDeletionDialog()" mat-menu-item>
				<mat-icon>delete</mat-icon>
				<span>Delete</span>
			</button>
		</mat-menu>
	</mat-card-actions>
</mat-card>
